import React, { useState, useEffect, useRef } from "react"
import WebriQForm from "@webriq/gatsby-webriq-form"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ExitIntent = ({
  onMobile,
  buttonLabel,
  heading,
  subHeading,
  btnLink,
  modalImg,
  surveyKey,
  compLogo,
  category,
  author,
}) => {
  const modalContainer = useRef(null)
  const [openOtherMessage, setOpenOtherMessage] = useState(false)
  const currentUrl = typeof window !== "undefined" ? window.location.href : ""

  // console.log("category", category)

  useEffect(() => {
    let timeout
    if (onMobile) {
      timeout = setTimeout(() => {
        const modalContainerRef = modalContainer.current
        if (modalContainerRef) {
          const finishSurvey = sessionStorage.getItem(surveyKey)
          if (!finishSurvey) {
            modalContainerRef.style.display = "block"
            sessionStorage.setItem(surveyKey, true)
          }
        }
      }, 5000)
    } else {
      timeout = setTimeout(() => {
        document.addEventListener("mouseleave", (e) => {
          const modalContainerRef = modalContainer.current
          if (!e.toElement && !e.relatedTarget && modalContainerRef) {
            const finishSurvey = sessionStorage.getItem(surveyKey)
            if (!finishSurvey) {
              modalContainerRef.style.display = "block"
              sessionStorage.setItem(surveyKey, true)
            }
          }
        })
      }, 5000)
    }

    return () => clearTimeout(timeout)
  }, [onMobile])

  const onModalClose = () => {
    const modalContainerRef = modalContainer.current
    modalContainerRef.style.display = "none"
  }

  useEffect(() => {
    window.onunload = function () {
      sessionStorage.removeItem(surveyKey)
    }
  })

  return (
    <>
      <div
        ref={modalContainer}
        className="exit-intent-popup vh-100 w-100 position-fixed"
        style={{
          display: "none",
          zIndex: 9999,
          top: 0,
          left: 0,
        }}
      >
        <div
          className="vh-100 w-100"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            top: 0,
            left: 0,
          }}
        >
          <div className="vh-100 position-relative ">
            <div>
              <div className="container">
                <div className="row vh-100">
                  <div className="col-lg-11 align-self-center mx-auto">
                    <div className="relative bg-white overflow-hidden">
                      <div className="row">
                        <div
                          className="col-lg-6"
                          style={{
                            background:
                              "url(https://cdn.sanity.io/images/eydex0le/production/194a08c95880c7685fe1924356986e7403c767dc-3407x3009.jpg)",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        ></div>
                        <div className="position-relative col-lg-6 align-self-center mx-auto  p-0">
                          <div
                            id="clost-btn"
                            onClick={onModalClose}
                            className="close-btn exit-intent-close-btn"
                            style={{
                              position: "absolute",
                              zIndex: "999999",
                              top: "5px",
                              right: "20px",
                              padding: "0 12px",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </div>
                          <div
                            className="bg-white"
                            style={{
                              padding: "40px 50px",

                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              borderRadius: "5px",
                            }}
                          >
                            {" "}
                            <div
                              className="text-center p-5"
                              style={{ border: "1px solid #022a4f" }}
                            >
                              {" "}
                              {/* {compLogo === "true" ? ( */}
                              <StaticImage
                                placeholder="blurred"
                                className="img-fluid"
                                src="https://cdn.sanity.io/images/eydex0le/production/f9eef960bd6796c1968f750b00beee7f19796ad0-395x395.png"
                                alt="logo"
                                width={65}
                                layout="constrained"
                                loading="lazy"
                              />
                              {/* ) : null} */}
                              <h2 className="font-weight-bold text-dark mt-4 mb-3">
                                {heading}
                              </h2>
                              <h5 className="mb-3 text-dark">{subHeading}</h5>
                              {}
                              <div className={`btn-box mt-5`}>
                                <WebriQForm
                                  data-form-id="53f1976a-40f1-4135-8a43-ace3110576fa"
                                  name="Newsletter"
                                  className="form-newsletter"
                                  method="POST"
                                >
                                  <div className="">
                                    <div className="col-12">
                                      <div className=" col-md-12">
                                        <div className="form-group">
                                          <input
                                            className="form-control"
                                            type="email"
                                            name="Email Address"
                                            id="email_address"
                                            required
                                            aria-label="email address"
                                            placeholder="Enter your email address"
                                            style={{
                                              border: "none",
                                              backgroundColor: "#f2f3f6",
                                              textAlign: "center",
                                            }}
                                          />
                                        </div>

                                        <div className="form-group d-none">
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="Author"
                                            id="author"
                                            value={
                                              author === undefined
                                                ? "No Author Input"
                                                : author
                                            }
                                            aria-label="Persona"
                                            style={{
                                              border: "none",
                                              backgroundColor: "#f2f3f6",
                                              textAlign: "center",
                                            }}
                                          />
                                        </div>

                                        <div className="form-group d-none">
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="Category"
                                            id="email_address"
                                            value={
                                              category === undefined
                                                ? "uncategorized"
                                                : category
                                            }
                                            aria-label="Persona"
                                            style={{
                                              border: "none",
                                              backgroundColor: "#f2f3f6",
                                              textAlign: "center",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-12">
                                      <div className="form-group">
                                        <div className="webriq-recaptcha" />
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="form-group">
                                        <button
                                          className="my-3 solid-btn-homepage"
                                          type="submit"
                                          style={{
                                            outline: "none",
                                            border: "none",
                                          }}
                                        >
                                          {buttonLabel}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </WebriQForm>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExitIntent
