import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import marked from "marked"
import slugify from "slugify"
import videoId from "get-youtube-id"
// import BlogSidebar from "../components/blogSidebar/sidebar"
import { BsPersonSquare } from "react-icons/bs"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
// import Helmet from "react-helmet"
import HexaGraphics from "../images/homepage/icons/hexa-graphics.png"

import { useDevice } from "react-use-device"

import ExitIntent from "../components/modal/exit-intent-blog"
import ExitIntentModal from "../components/modal/exit-intent-modal"
import CustomArticles from "../components/CustomArticles"
import CustomContactForm from "../components/customContactForm"
import { BsFacebook } from "react-icons/bs"
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai"
import {
  TiSocialYoutubeCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti"

import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"
// import hexagonalIcon from "../images/homepage/icons/HEXAGON-shape.png"
import Sticky from "react-sticky-el"

const BlogTemplate = (props) => {
  const breakpoints = {
    tablet: 650,
    laptop: 980,
    desktop: 1200,
  }

  const { isMOBILE, isTABLET } = useDevice(breakpoints)

  // console.log("post", allposts)

  const [headingContent, setHeadingContent] = useState([])
  const [headingContentH1, setHeadingContentH1] = useState([])
  useEffect(() => {
    const elementTag = document.getElementById("headingDiv")
    let nodes = elementTag.getElementsByTagName("h3")
    let nodesH1 = elementTag.getElementsByTagName("h1")
    const arrayNodes = Object.values(nodes)
    const arrayNodesH1 = Object.values(nodesH1)
    const selectedHeadings =
      arrayNodes &&
      arrayNodes.map((item) => {
        return [
          {
            title: item.innerText,
            id: item.id,
          },
        ]
      })
    let flattened = selectedHeadings.reduce(
      (acc, curVal) => acc.concat(curVal),
      []
    )
    let flattenedH1 = arrayNodesH1.reduce(
      (acc, curVal) => acc.concat(curVal),
      []
    )
    setHeadingContent(flattened)
    setHeadingContentH1(flattenedH1)
  }, [])

  // MODIFY ALL INTERNAL LINKS to add trailing slash
  useEffect(() => {
    var els = document.querySelectorAll(
      "a[href^='https://www.baystreetcapitalholdings.com']"
    )
    for (var i = 0, l = els.length; i < l; i++) {
      var el = els[i]
      // console.log(el.href)
      if (!el.href.endsWith("/")) {
        el = el.setAttribute("href", el.href + "/")
      }
    }
    var elsExternal = document.querySelectorAll("a")
    for (var i = 0, l = elsExternal.length; i < l; i++) {
      var elN = elsExternal[i]
      // console.log(el.href)
      if (!elN?.href?.includes("https://www.baystreetcapitalholdings.com")) {
        // elN = elN?.setAttribute("target", "_blank")
        function setAttributes(el, attrs) {
          for (var key in attrs) {
            el.setAttribute(key, attrs[key])
          }
        }
        setAttributes(elN, { target: "_blank", rel: "noreferrer" })
      }
    }
  }, [])

  const { post, allSanityPost, allSanityCategory, site } = props.data
  const posts = allSanityPost
  const allposts = allSanityPost.edges.map((t) => t.node)
  const categories = allSanityCategory.edges.map((c) => c.node)
  const { previous, next } = props.pageContext

  useEffect(() => {
    const myAnchor = document.getElementById("headingDiv")
    let originalElement = myAnchor.getElementsByTagName("h1")
    let htwo = document.createElement("h2")
    for (var i = 0, l = originalElement.length; i < l; i++) {
      var elN = originalElement[i]
      if (originalElement !== undefined || originalElement !== null) {
        htwo.setAttribute("id", elN.id)
        htwo.innerHTML = elN.innerHTML
        myAnchor.prepend(htwo)
        htwo.style.fontSize = "2.5rem"
      }
    }
  }, [])

  console.log("post", post)

  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={
          post?.metaDescription?.length > 156
            ? `${post?.metaDescription.substring(0, 154)}...`
            : post?.metaDescription
            ? post?.metaDescription
            : site?.siteMetadata?.description
        }
        metakeywords={post.metakeywords}
      />
      <div className="blog-page">
        <div style={{ paddingTop: "180px" }}>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div
                className="col-lg-6 blog-video"
                style={{
                  overflow: "hidden",
                  textAlign: "center",
                  margin: "auto 0",
                }}
              >
                {post?.videoUrl ? (
                  <iframe
                    src={post?.videoUrl}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                    style={{ width: "100%" }}
                  ></iframe>
                ) : (
                  <GatsbyImage
                    image={post?.mainImage?.asset?.gatsbyImageData}
                    className=""
                    alt={post?.authors?.name ? post.authors.name : null}
                    // style={{ width: "100%", height: "876px" }}
                  />
                )}
              </div>
              <div className="col-lg-6 p-5 align-self-center">
                <div className="d-flex align-items-center mb-3">
                  <Link
                    to="/blog/"
                    className="mb-1"
                    style={{
                      marginRight: "8px",
                      fontWeight: "bold",
                      color: "#07315b",
                    }}
                  >
                    All Articles
                  </Link>
                  <i
                    class="fa fa-chevron-right ml-2"
                    style={{ fontWeight: "bold", color: "#07315b" }}
                  />
                  {post?.categories[0]?.title && (
                    <Link
                      to={`/category/${slugify(
                        post?.categories[0]?.title
                      ).toLocaleLowerCase()}/`}
                      className="mb-1"
                      style={{
                        marginLeft: "8px",
                        fontWeight: "bold",
                        color: "#07315b",
                      }}
                    >
                      {post?.categories[0]?.title}
                    </Link>
                  )}
                </div>
                <div className="w-100">
                  <h2 className="text-capitalize font-weight-bold text-black">
                    {post.title}
                  </h2>
                  {post.topBody && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(post?.topBody),
                      }}
                      className="text-black"
                    />
                  )}
                </div>
                <div className="d-flex mt-3">
                  {post?.authors?.image?.asset?.gatsbyImageData ? (
                    <GatsbyImage
                      image={post?.authors?.image?.asset?.gatsbyImageData}
                      className="img-fluid rounded-full align-self-center"
                      alt={post?.authors?.name ? post.authors.name : null}
                      style={{ width: "40px" }}
                    />
                  ) : (
                    <BsPersonSquare className="align-self-center" size="2rem" />
                  )}
                  <p
                    className="text-capitalized font-weight-bold text-black align-self-center"
                    style={{ paddingLeft: "20px" }}
                  >
                    {post?.authors?.name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {post.categories.some((cat) => cat?.title === "Insurance") ? (
          <ExitIntentModal />
        ) : (
          <ExitIntent
            btnLink="/case-studies"
            buttonLabel="SEND ME INSIGHTS"
            heading="ENJOYING THE READ?"
            subHeading="Join our list to receive the latest insights from our experts and diversify your thinking. "
            surveyKey="proc2Modal"
            onMobile={false}
            author={post?.authors?.name}
            category={post?.categories[0]?.title}
          />
        )}

        <div
          className="block blog-content-wrapper mt-4"
          style={{ paddingBottom: "50px" }}
        >
          <div className={`${isMOBILE ? "container" : "container-fluid"}`}>
            <div className="row justify-content-center">
              <div className="col-md-3"></div>
              <div className="col-md-8 align-self-center">
                <div className="authors-wrap pb-3 social-wrap d-flex pt-0">
                  <p
                    className="align-self-center"
                    style={{ paddingRight: "20px" }}
                  >
                    Follow us for more exclusive content
                  </p>
                  <ul className="list-unstyled d-flex align-self-center">
                    <li className="mx-2">
                      <a
                        target="_blank"
                        href="https://www.facebook.com/baystreetcapitalholdings"
                      >
                        <StaticImage
                          src="../images/fb-icon.png"
                          alt="fb"
                          loading="lazy"
                          style={{
                            width: "45px",
                            height: "45px",
                          }}
                        />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a
                        target="_blank"
                        href="https://www.instagram.com/baystreetcapitalholdings/"
                      >
                        <StaticImage
                          src="../images/instagram-icon.png"
                          alt="instagram"
                          loading="lazy"
                          style={{
                            width: "45px",
                            height: "45px",
                          }}
                        />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a
                        target="_blank"
                        href="https://twitter.com/BayStreetSpeaks"
                      >
                        <StaticImage
                          src="../images/twitter-icon.png"
                          alt="twitter"
                          loading="lazy"
                          style={{
                            width: "45px",
                            height: "45px",
                          }}
                        />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/channel/UCrW--z73v5yTiqbrw8QyfkA"
                      >
                        <StaticImage
                          src="../images/youtube-icon.png"
                          alt="youtube"
                          loading="lazy"
                          style={{
                            width: "45px",
                            height: "45px",
                          }}
                        />
                      </a>
                    </li>
                    <li className="mx-2">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/bay-street-capital-holdings/"
                      >
                        <StaticImage
                          src="../images/linkedin-icon.png"
                          alt="linkedin"
                          loading="lazy"
                          style={{
                            width: "45px",
                            height: "45px",
                          }}
                        />
                      </a>
                    </li>
                  </ul>
                  {/* <ul className="list-unstyled d-flex align-self-center">
                    <li className="mx-2">
                      <FacebookShareButton
                        url={`https://www.baystreetcapitalholdings.com/${post.slug.current}/`}
                      >
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>
                    </li>
                    <li className="mx-2">
                      <TwitterShareButton
                        url={`https://www.baystreetcapitalholdings.com/${post.slug.current}/`}
                      >
                        <TwitterIcon size={40} round={true} />
                      </TwitterShareButton>
                    </li>
                    <li className="mx-2">
                      <LinkedinShareButton
                        url={`https://www.baystreetcapitalholdings.com/${post.slug.current}/`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedinIcon size={40} round={true} />
                      </LinkedinShareButton>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="row">
                <div className="col-md-8 mx-auto table-wrapper-mobile">
                  <div
                    className="table-of-content-sidebar"
                    style={{ marginBottom: "20px", overflowY: "auto" }}
                  >
                    <h3
                      className="text-uppercase mb-3"
                      style={{ color: "#9badbf" }}
                    >
                      Table of Contents
                    </h3>
                    <ul className="list-unstyled table-of-content-blog">
                      {headingContentH1 && (
                        <li>
                          <a href={`#${headingContentH1[0]?.id}`}>
                            {headingContentH1[0]?.innerText}
                          </a>
                        </li>
                      )}

                      {headingContent &&
                        headingContent?.map((anchor) => (
                          <li className="">
                            <a className="" href={`#${anchor?.id}`}>
                              {anchor.title}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  isTABLET ? "px-2" : "px-5"
                } col-md-3 table-wrapper`}
              >
                <div className="table-top">
                  {!isTABLET && (
                    <Sticky
                      boundaryElement=".block"
                      hideOnBoundaryHit={true}
                      topOffset={50}
                    >
                      <div
                        className="table-of-content-sidebar"
                        style={{ overflowY: "auto" }}
                      >
                        <h3
                          className="text-uppercase mb-3"
                          style={{ color: "#9badbf" }}
                        >
                          Table of Contents
                        </h3>
                        <ul className="list-unstyled table-of-content-blog">
                          {headingContentH1 && (
                            <li>
                              <a href={`#${headingContentH1[0]?.id}`}>
                                {headingContentH1[0]?.innerText}
                              </a>
                            </li>
                          )}

                          {headingContent &&
                            headingContent?.map((anchor) => (
                              <li className="">
                                <a className="" href={`#${anchor?.id}`}>
                                  {anchor.title}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </Sticky>
                  )}
                  {isTABLET && (
                    <div
                      className="table-of-content-sidebar"
                      style={{ overflowY: "auto" }}
                    >
                      <h3
                        className="text-uppercase mb-3"
                        style={{ color: "#9eb0c1" }}
                      >
                        Table of Content
                      </h3>
                      <ul className="list-unstyled table-of-content-blog">
                        {headingContentH1 && (
                          <li>
                            <a href={`#${headingContentH1[0]?.id}`}>
                              {headingContentH1[0]?.innerText}
                            </a>
                          </li>
                        )}

                        {headingContent &&
                          headingContent?.map((anchor) => (
                            <li className="">
                              <a className="" href={`#${anchor?.id}`}>
                                {anchor.title}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="block col-md-8 mb-5">
                <div
                  id="headingDiv"
                  className="mb-5 pr-5"
                  dangerouslySetInnerHTML={{
                    __html: marked(
                      post.body.replace(
                        /!\[([^\]]+)\]\(([^)]+)\)/g,
                        '<img alt="$1" src="$2">'
                      )
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <CustomArticles posts={posts} />
        <CustomContactForm />
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($id: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    post: sanityPost(id: { eq: $id }) {
      id
      mainImage {
        asset {
          gatsbyImageData
        }
      }
      publishedAt(formatString: "MMMM DD, YYYY")
      body
      title
      videoUrl
      topBody
      slug {
        current
      }
      categories {
        id
        title
      }
      authors {
        name
        image {
          asset {
            gatsbyImageData(width: 200, height: 200)
          }
        }
      }
      metaDescription
      metaTitle
    }
    allSanityCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      limit: 5
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          featured
          mainImage {
            asset {
              gatsbyImageData
            }
            alt
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          excerpt
          videoUrl
          categories {
            id
            title
          }
          authors {
            name
            biography {
              quotation
            }
            image {
              asset {
                gatsbyImageData(width: 32, height: 32)
              }
            }
          }
          metaDescription
          metaTitle
        }
      }
    }
  }
`
