import React, { useState, useEffect, useRef } from "react"
import WebriQForm from "@webriq/gatsby-webriq-form"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const ExitIntentModal = ({
  onMobile,
  buttonLabel,
  heading,
  subHeading,
  btnLink,
  modalImg,
  surveyKey,
  compLogo,
}) => {
  const modalContainer = useRef(null)
  const [openOtherMessage, setOpenOtherMessage] = useState(false)
  const currentUrl = typeof window !== "undefined" ? window.location.href : ""

  useEffect(() => {
    let timeout
    if (onMobile) {
      timeout = setTimeout(() => {
        const modalContainerRef = modalContainer.current
        if (modalContainerRef) {
          const finishSurvey = sessionStorage.getItem(surveyKey)
          if (!finishSurvey) {
            modalContainerRef.style.display = "block"
            sessionStorage.setItem(surveyKey, true)
          }
        }
      }, 5000)
    } else {
      timeout = setTimeout(() => {
        document.addEventListener("mouseleave", (e) => {
          const modalContainerRef = modalContainer.current
          if (!e.toElement && !e.relatedTarget && modalContainerRef) {
            const finishSurvey = sessionStorage.getItem(surveyKey)
            if (!finishSurvey) {
              modalContainerRef.style.display = "block"
              sessionStorage.setItem(surveyKey, true)
            }
          }
        })
      }, 5000)
    }

    return () => clearTimeout(timeout)
  }, [onMobile])

  const onModalClose = () => {
    const modalContainerRef = modalContainer.current
    modalContainerRef.style.display = "none"
  }

  useEffect(() => {
    window.onunload = function () {
      sessionStorage.removeItem(surveyKey)
    }
  })

  return (
    <>
      <div
        ref={modalContainer}
        className="exit-intent-popup vh-100 w-100 position-fixed"
        style={{
          display: "none",
          zIndex: 9999,
          top: 0,
          left: 0,
        }}
      >
        <div
          className="vh-100 w-100"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            top: 0,
            left: 0,
          }}
        >
          <div className="vh-100 position-relative ">
            <div>
              <div className="container">
                <div className="row vh-100">
                  <div className="col-lg-11 align-self-center mx-auto">
                    <div className="relative bg-white overflow-hidden">
                      <div className="row">
                        <div
                          className="col-lg-6"
                          style={{
                            background:
                              "url(https://cdn.sanity.io/images/eydex0le/production/194a08c95880c7685fe1924356986e7403c767dc-3407x3009.jpg)",
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        ></div>
                        <div className="position-relative col-lg-6 align-self-center mx-auto  p-0">
                          <div
                            id="clost-btn"
                            onClick={onModalClose}
                            className="close-btn exit-intent-close-btn"
                            style={{
                              position: "absolute",
                              zIndex: "999999",
                              top: "5px",
                              right: "20px",
                              padding: "0 12px",
                              color: "#000",
                              cursor: "pointer",
                            }}
                          >
                            x
                          </div>
                          <div
                            className="bg-white"
                            style={{
                              padding: "40px 50px",

                              backgroundSize: "cover",
                              backgroundPosition: "center center",
                              borderRadius: "5px",
                            }}
                          >
                            {" "}
                            <div
                              className="text-center p-5"
                              style={{ border: "1px solid #022a4f" }}
                            >
                              {" "}
                              {/* {compLogo === "true" ? ( */}
                              <StaticImage
                                placeholder="blurred"
                                className="img-fluid"
                                src="https://cdn.sanity.io/images/eydex0le/production/f9eef960bd6796c1968f750b00beee7f19796ad0-395x395.png"
                                alt="logo"
                                width={65}
                                layout="constrained"
                                loading="lazy"
                              />
                              {/* ) : null} */}
                              <h4 className="font-weight-bold text-dark mt-4 mb-3">
                                Bay Street, one of the top 10 largest
                                black-owned investment firms in the US, invites
                                you to apply for life insurance, the easy way.
                              </h4>
                              <p className="font-weight-bold text-dark mt-4 mb-3">
                                No medical exams, just a few health questions
                                online. Get up to $2 million in same-day
                                coverage in under 2 minutes.
                              </p>
                              <a
                                className="my-3 solid-btn-homepage"
                                href="https://agents.ethoslife.com/invite/fa4fd"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                  outline: "none",
                                  border: "none",
                                }}
                              >
                                CLICK HERE
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExitIntentModal
